  // 详情弹出框
<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    title="文件履历"
    class="resume"
  >
    <titleTemplate :name="'文件类型：' + fileTypeName" />
    <base-table
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
        height:450
      }"
      ref="tableData"
      :webPage="true"
      class="main-page-table"
      :queryParas.sync="queryparas"
      @pageSizeChange='pageSizeChange'
      @pageIndexChange='pageIndexChange'
      :is-caculate-height="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          content="预览"
          @click.prevent="fileview(scope.row)"
          icon="iconfont iconchakan"
        ></icon-button>
      </template>
    </base-table>
    <template slot="footer">
      <base-button label="关闭" @click="visibleState = false" type="default" />
    </template>
     <!-- 文件预览 -->
      <pre-view-new :count="count" :fileId="fileId" :fileType="fileType" ref='filsResumeDatails' :isOpen="true" />
  </base-dialog>
</template>
<script>
import titleTemplate from '@/pages/business/components/titleTemplate.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { tableDataConfig } from '../utils/files-resume-config'
import { supplierFileApi } from '@/api/companyApi'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import PreViewNew from '@/components/pre-view/pre-view.vue'

export default {
  props: {
    visible: Boolean,
    filesResumeData: Array,
    fileTypeName: String
  },
  data () {
    return {
      count: 1,
      loadCount: 0,
      tableData: [],
      fileId: '',
      fileType: '',
      queryparas: {
        pageSize: 10,
        pageIndex: 1
      }
    }
  },
  components: { titleTemplate, BaseDialog, BaseTable, BaseButton, IconButton, PreViewNew },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    columns () {
      return tableDataConfig(this)
    },
    api () {
      return supplierFileApi
    }
  },
  watch: {
    visibleState (val) {
      if (val) {
        this.pageIndexChange(this.queryparas.pageIndex)
        this.getTableData(this.queryparas.pageIndex, this.queryparas.pageSize)
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.tableData.total = this.filesResumeData.length
          })
        })
      } else {
        this.queryparas = {
          pageSize: 10,
          pageIndex: 1
        }
      }
    }
  },
  methods: {
    getTableData (page, size) {
      const newArr = []
      this.filesResumeData.forEach((item, index) => {
        if (page === 1) {
          if (index < size * page) {
            newArr.push(item)
          }
        } else {
          if (index >= (page - 1) * size && index < size * page) {
            newArr.push(item)
          }
        }
      })
      this.tableData = newArr
    },
    pageSizeChange (size) {
      this.queryparas.pageSize = size
      this.getTableData(this.queryparas.pageIndex, this.queryparas.pageSize)
    },
    pageIndexChange (page) {
      this.queryparas.pageIndex = page
      this.getTableData(this.queryparas.pageIndex, this.queryparas.pageSize)
    },
    // 查看单个文件详情
    fileview (row) {
      this.fileId = row.fileId
      const fileSuffix = row.fileName ? row.fileName.split('.') : []
      this.fileType = fileSuffix[fileSuffix.length - 1]
      // this.$refs.filsResumeDatails.open()
      this.count++
    }
  }

}
</script>
<style lang="scss" scoped>

</style>
