import { formatDate } from '@/utils/auth/common'

export const tableDataConfig = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '文件名称',
      prop: 'fileName',
      minWidth: '150px'
    },
    {
      label: '上传人',
      prop: 'uploadUser',
      minWidth: '250px'
    },
    {
      label: '上传时间',
      prop: 'uploadTime',
      minWidth: '150px',
      formatter: row => {
        return formatDate(row.uploadTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
